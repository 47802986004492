
/* all element */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}



button {
    background-color: inherit ;
}

.fs-18 {
    font-size: 18px;
}
.fs-16 {
    font-size: 16px;
}

.fs-12 {
    font-size: 12px;
}

.fw-7 {
    font-weight: 700;
}

.fw-5 {
    font-weight: 500;
}

.ant-dropdown-trigger {
    background-color: inherit;
    color: #fff;
}

.ant-dropdown-trigger:hover, .ant-dropdown-trigger:focus {
    border-color:#fff;
    background-color: inherit;
    color: #fff;
    
}

.wallet-address {
    color:#887878;
}

.table {
    background: rgba(88, 88, 88, 0.5);
    border-radius: 10px;
}

.text-brown {
    color: #887878; 
}

.ant-pagination-total-text {
    color: #fff;
    background-color: inherit;
}

.ant-pagination-options-quick-jumper {
    color: #fff;
}

.ant-btn {
    border-radius: 10px;
}

table tr td, th {
    width: 25%;
}

.ant-pagination .ant-pagination-item-link, .ant-pagination-item, .ant-pagination-options input {
    border-radius: 10px;
    background-color: inherit;
    color: #fff;
}


.tabledata {
    width: 80%;
    margin: 0 auto;
}

.border-bottom-none {
    border-bottom: none;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.logout .logout-icon {
    width: 20px;
    height: 20px;
}

.activity-icon {
    width: 30px;
    height: 30px;
}



/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .main {
        background-image: linear-gradient(103.33deg, #1A151B 3.32%, #3F1D42 49.7%, #441748 92.33%);
        width: 100vw;
        min-height: 100vh;
    }

    .page-icon {
        width: 30px;
        height: 30px;
        color: #887878;
    }

    .wallet-box {
        min-width: 60%;
        border: 2px solid #EB42E4;
        border-radius: 10px;
        margin: 0 auto;
        padding: 20px;
    }
    
    .connect-text {
        font-size: 16px;
        font-weight: 700;
        padding: 5px;
    }
   
    
    .sidebar {
        position: relative;
        padding-left: 0;
    }
    
    
    .top-sidebar {
        padding: 40px 30px ;
        border-bottom: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .top-sidebar img {
        padding-bottom: 25px;
        max-width: 100%;
    }

    .wallet-label {
        font-size: 16px;
    }

    .wallet-address {
        font-size: 12px;
    }
    
    .box-show {
        max-width: 50%;
        background: rgba(180, 169, 169, 0.19);
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0 ;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
    
    
    
    .box-hide {
        max-width: 50%;
        margin: 10px auto;
        padding: 6px 0 ;
        color:#887878;
        border: 1px solid transparent;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
    
    
    .logout {
        position: absolute;
        bottom: 5%;
        left: 30%;
        font-size: 16px;
        font-weight: 500;
    }
    
    
    .topbar {
        border-bottom: 1px solid #fff;
        font-size: 16px;
       
    }

    .menu {
        display: flex;
        justify-content: center;
    }
    
    .topbar a {
        padding: 20px 10px;
        text-decoration: none;
        color: #887878;
    }
    
    .pool-btn {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }
    
    .content .display-token {
        padding: 20px 0 20px 0;
        display: flex;
        gap: 5%;
        justify-content: center;
        text-align: center;
        color: #ffffff;
    }
    
    .content .display-token .display-token-box {
        width: 20%;
        padding: 10px 0;
        background: rgba(88, 88, 88, 0.5);
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
    }
    
    .activity-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto;
        padding-top: 5%;
    }
    
    .dashboard-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto
    }


    
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main {
        background-image: linear-gradient(103.33deg, #1A151B 3.32%, #3F1D42 49.7%, #441748 92.33%);
        width: 100vw;
        min-height: 100vh;
    }

    .page-icon {
        width: 30px;
        height: 30px;
        color: #887878;
    }

    .wallet-box {
        min-width: 60%;
        border: 2px solid #EB42E4;
        border-radius: 10px;
        margin: 0 auto;
        padding: 20px;
    }

    .connect-text {
        font-size: 16px;
        font-weight: 700;
        /* padding: 5px; */
    }   
    
    .wallet-label {
        font-size: 16px;
    }

    .wallet-address {
        font-size: 12px;
    }
   
    
    .sidebar {
        position: relative;
        padding-left: 0;
    }
    
    
    .top-sidebar {
        padding: 40px 30px ;
        border-bottom: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .top-sidebar img {
        padding-bottom: 25px;
        max-width: 100%;
    }
    
    .box-show {
        width: 60%;
        background: rgba(180, 169, 169, 0.19);
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0 ;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
    
    
    
    .box-hide {
        width: 60%;
        margin: 10px auto;
        padding: 6px 0 ;
        color:#887878;
        border: 1px solid transparent;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
    
    
    .logout {
        position: absolute;
        bottom: 5%;
        left: 30%;
        font-size: 16px;
        font-weight: 500;
        
    }
    
    
    .topbar {
        border-bottom: 1px solid #fff;
        font-size: 16px;
    }

    .menu {
        display: flex;
        justify-content: center;
    }
    
    .topbar a {
        padding: 20px 10px;
        text-decoration: none;
        color: #887878;
    }

    .pool-btn {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }
    
    
    .content .display-token {
        padding: 20px 0 20px 0;
        display: flex;
        gap: 5%;
        justify-content: center;
        text-align: center;
        color: #ffffff;
    }
    
    .content .display-token .display-token-box {
        width: 20%;
        padding: 10px 0;
        background: rgba(88, 88, 88, 0.5);
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
    }
    
    .activity-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto;
        padding-top: 5%;
    }
    
    .dashboard-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto
    }
    
} 

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {

    .main {
        background-image: linear-gradient(103.33deg, #1A151B 3.32%, #3F1D42 49.7%, #441748 92.33%);
        width: 100vw;
        min-height: 100vh;
    }

    .page-icon {
        width: 25px;
        height: 25px;
        color: #887878;
    }

    .wallet-box {
        max-width: 90%;
        border: 2px solid #EB42E4;
        border-radius: 10px;
        margin: 0 auto;
        padding: 15px;
    }

    .connect-text {
        font-size: 15px;
        font-weight: 600;
    }
    
    .wallet-label {
        font-size: 13px;
    }

    .wallet-address {
        padding-top: 5px;
        font-size: 11px;
    }
   
    
    .sidebar {
        position: relative;
        padding-left: 0;
    }
    
    
    .top-sidebar {
        padding: 40px 30px ;
        border-bottom: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .top-sidebar img {
        padding-bottom: 25px;
        max-width: 100%;
    }
    
    .box-show {
        width: 60%;
        background: rgba(180, 169, 169, 0.19);
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0 ;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    
    
    
    .box-hide {
        width: 60%;
        margin: 10px auto;
        padding: 6px 0 ;
        color:#887878;
        border: 1px solid transparent;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    
    
    .logout {
        position: absolute;
        bottom: 3%;
        left: 25%;
        font-size: 14px;
        font-weight: 500;
    }
    

    
    .topbar {
        border-bottom: 1px solid #fff;
        font-size: 15px;
    }

    .menu {
        display: flex;
        justify-content: center;
    }
    

    .topbar a {
        padding:10px;
        text-decoration: none;
        color: #887878;
        text-align: center;
    }

    .pool-btn {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }
    
    
    .content .display-token {
        padding: 20px 0 20px 0;
        display: flex;
        gap: 5%;
        justify-content: center;
        text-align: center;
        color: #ffffff;
    }
    
    .content .display-token .display-token-box {
        width: 20%;
        padding: 10px 0;
        background: rgba(88, 88, 88, 0.5);
        border-radius: 10px;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
    }
    
    .activity-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto;
        padding-top: 5%;
    }
    
    .dashboard-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto
    }
} 




@media only screen and (min-width: 600px) and (max-width: 767px) {
    .main {
        background-image: linear-gradient(103.33deg, #1A151B 3.32%, #3F1D42 49.7%, #441748 92.33%);
        width: 100vw;
        min-height: 1250px;
        position: relative;
    }

    .page-icon {
        width: 25px;
        height: 25px;
        color: #887878;
    }

    .wallet-box {
        width: 40%;
        border: 2px solid #EB42E4;
        border-radius: 10px;
        margin: 0 auto;
        padding: 10px;
    }

    .connect-text {
        font-size: 16px;
        font-weight: 600;
        padding: 5px;
    }
    
    .wallet-label {
        font-size: 16px;
    }

    .wallet-address {
        padding-top: 5px;
        font-size: 12px;
    }
   
    
    .sidebar {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding-left: 0;

    }
    
    
    .top-sidebar {
        padding: 40px 30px 20px 30px;
        border-bottom: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .top-sidebar img {
        padding-bottom: 25px;
        max-width: 90%;
    }

    .bot-sidebar {
        position: absolute;
        top: 220px;
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    
    .box-show {
        width: 30%;
        background: rgba(180, 169, 169, 0.19);
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0 ;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    
    
    
    .box-hide {
        width: 30%;
        margin: 5px;
        padding: 6px 0 ;
        color:#887878;
        border: 1px solid transparent;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    
    
    .logout {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }
    
    
    .topbar {
        font-size: 15px;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .menu {
        margin: 10px 0 10px 0;
        display: flex;
        justify-content: center;
        gap: 0 30px;
        text-align: center;
    }
    
    .topbar a {
        text-decoration: none;
        color: #887878;
        font-size: 14px;
    }

    .pool-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.5rem;

    }

    .content {
        position: absolute;
        width: 100%;
        top: 500px;
    }
    
    
    .content .display-token {
        padding: 20px 0 20px 0;
        display: flex;
        gap: 5%;
        justify-content: center;
        text-align: center;
        color: #ffffff;
    }
    
    .content .display-token .display-token-box {
        width: 20%;
        padding: 10px 0;
        background: rgba(88, 88, 88, 0.5);
        border-radius: 10px;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
    }
    
    .activity-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto;
        padding-top: 5%;
    }
    
    .dashboard-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto
    }

  

    .border-end {
        border-right: none!important;
    }
  }



  @media only screen and (max-width: 599px) {
    .main {
        background-image: linear-gradient(103.33deg, #1A151B 3.32%, #3F1D42 49.7%, #441748 92.33%);
        width: 100vw;
        min-height: 1450px;
        position: relative;
    }

    .page-icon {
        width: 20px;
        height: 20px;
        color: #887878;
    }

    .wallet-box {
        width: 40%;
        border: 2px solid #EB42E4;
        border-radius: 10px;
        margin: 0 auto;
        padding: 10px;
    }

    .connect-text {
        font-size: 16px;
        font-weight: 600;
        padding: 5px;
    }
    
    .wallet-label {
        font-size: 16px;
    }

    .wallet-address {
        padding-top: 5px;
        font-size: 12px;
    }
   
    
    .sidebar {
        position: absolute;
        top: 20px;
        width: 100%;
        padding-left: 0;
    }
    
    
    .top-sidebar {
        padding: 40px 30px 20px 30px;
        border-bottom: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .top-sidebar img {
        padding-bottom: 25px;
        max-width: 90%;
    }

    .bot-sidebar {
        position: absolute;
        top: 220px;
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .box-show {
        width: 30%;
        background: rgba(180, 169, 169, 0.19);
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0 ;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    
    
    
    .box-hide {
        width: 30%;
        margin: 5px;
        padding: 6px 0 ;
        color:#887878;
        border: 1px solid transparent;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    
    
    .logout {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }
    
    
    .topbar {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .menu {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 0 30px;
        text-align: center;
    }
    
    .topbar a {
        text-decoration: none;
        color: #887878;
        font-size: 13px;
    }

    .pool-btn {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;
    }

    .content {
        position: absolute;
        width: 100%;
        top: 500px;
        color: white;
    }
    
    
    .content .display-token {
        padding: 20px 0 20px 0;
        display: flex;
        gap: 5%;
        justify-content: center;
        text-align: center;
        color: #ffffff;
    }
    
    .content .display-token .display-token-box {
        width: 20%;
        padding: 5px 0;
        background: rgba(88, 88, 88, 0.5);
        border-radius: 10px;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
    }
    
    .activity-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto;
        padding-top: 5%;
    }
    
    .dashboard-img {
        max-width: 50%;
        height: auto;
        margin: 0 auto
    }


    .border-end {
        border-right: none!important;
    }
  }